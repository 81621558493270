import { graphql, PageProps } from 'gatsby'
import { ISectionData } from '../utils/renderSection'
import { CasesProvider } from '../contexts/cases-context-provider/CasesProvider'
import PageMainContent from '../components/page-main-content'
import { IStrapiCase, IStrapiIndustry, IStrapiSeo, IStrapiService } from '../types/strapi'
import PageSEO from '../components/seo'
import { Typography } from '@mui/material'
import SectionLayout from '../layouts/section-layout/SectionLayout'
import MainForSection, { IMainFormSectionData } from '../components/page-sections/main-form-section/MainFormSection'

interface IStrapiCasesResponse {
  strapiCasesPage: {
    seo: IStrapiSeo
    sections: Array<ISectionData>
    mainForm: IMainFormSectionData
  }
}

interface IStrapiPageContext {
  cases: Array<IStrapiCase>
  industries: Array<IStrapiIndustry>
  services: Array<IStrapiService>
  pageTitle?: string
  pageSEO?: IStrapiSeo
}

type CasesPageProps = PageProps<IStrapiCasesResponse, IStrapiPageContext>

export const Head = ({ data, location, pageContext }: CasesPageProps) => {
  const { seo } = data.strapiCasesPage
  const { pageSEO } = pageContext

  return (
    <PageSEO
      title={pageSEO?.title || seo.title}
      description={pageSEO?.description || seo.description}
      pathname={location.pathname}
    />
  )
}

const CasesPage = (props: CasesPageProps) => {
  const {
    data: {
      strapiCasesPage: { mainForm, sections = [] },
    },
    pageContext,
    location,
  } = props

  const isCasesWithFilterPage = location.pathname !== '/cases/'
  const filteredSections = sections.filter(section => (!isCasesWithFilterPage || section.options.anchor !== 'hide-in-filters'))

  return (
    <CasesProvider context={pageContext}>
      <SectionLayout options={sections[0].options}>
        <Typography variant='h1' color='text.primary'>{pageContext.pageTitle || 'Cases'}</Typography>
      </SectionLayout>
      <PageMainContent sections={filteredSections} />
      <MainForSection data={mainForm} />
    </CasesProvider>
  )
}

export default CasesPage

export const query = graphql`
  query {
    strapiCasesPage {
      seo {
        ...StrapiSEO
      }
      mainForm {
        ...StrapiMainFormSection
      }
      sections {
        ... on STRAPI__COMPONENT_SECTIONS_DEFAULT_SECTION {
          ...StrapiDefaultSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_QUOTE_SECTION {
          ...StrapiQuoteSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_CASES_SECTION {
          ...StrapiCasesSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_LIST_SECTION {
          ...StrapiListSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_ICON_LIST_SECTION {
          ...StrapiIconListSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_CAROUSEL_SECTION {
          ...StrapImagesCarouselSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_REVIEWS_CAROUSEL_SECTION {
          ...StrapReviewsCarouselSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_LICENSES_CAROUSEL_SECTION {
          ...StrapiLicensesCarouselSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_CARDS_SECTION {
          ...StrapiBasicCardsSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_TEXT_CARDS_SECTION {
          ...StrapiTextCardsSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_IMAGE_CARDS_SECTION {
          ...StrapiImageCardsSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_LICENSE_CARDS_SECTION {
          ...StrapiLicenseCardsSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_REVIEW_CARDS_SECTION {
          ...StrapiReviewCardsSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_TABS_LAYOUT {
          ...StrapiTabsLayout
        }
        ... on STRAPI__COMPONENT_SECTIONS_GRID_LAYOUT {
          ...StrapiGridLayout
        }
        ... on STRAPI__COMPONENT_SECTIONS_IMAGE_SECTION {
          ...StrapiImageSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_CASES_CAROUSEL_SECTION {
          ...StrapCasesCarouselSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_BUTTONS_SECTION {
          ...StrapiButtonsSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_CASES_SELECTION_SECTION {
          ...StrapiCasesSelectionSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_WYSIWYG_SECTION {
          ...StrapiWysiwygSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_BLOG_SECTION {
          ...StrapiBlogSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_STEPS_SECTION {
          ...StrapiStepsSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_FAQ_SECTION {
          ...StrapiFaqSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_SOCIALS_SECTION {
          ...StrapiSocialsSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_BREAD_CRUMBS_SECTION {
          ...StrapiBreadCrumbsSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_TABS_LIST_SECTION {
          ...StrapiTabsListSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_TEXT_CARDS_CAROUSEL_SECTION {
          ...StrapiTextCardsCarouselSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_ACTION_CARDS_SECTION {
          ...StrapiActionCardsSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_TABLE_SECTION {
          ...StrapiTableSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_PRICE_TAGS_SECTION {
          ...StrapiPriceTagsSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_PRICE_CARDS_SECTION {
          ...StrapiPriceCardsSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_SIMPLE_CARDS_SECTION {
          ...StrapiSimpleCardsSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_HOVERING_SECTION {
          ...StrapiHoveringSection
        }
      }
    }
  }
`
